import React, { useRef, useEffect } from "react";
import styles from "./styles.module.scss";
import mapboxgl from "mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax

const Map = () => {
	mapboxgl.accessToken =
		"pk.eyJ1Ijoia3J5cHQtbGV2aSIsImEiOiJjbG9oZTN2M3YxNzFsMnFvaXZ3NDhmNWNtIn0.g850SWh2nbiYonPy2BASIw";
	const mapContainer = useRef(null);
	const map = useRef(null);
	const lng = 3.45170307159424;
	const lat = 6.46042251586914;
	const zoom = 15;

	useEffect(() => {
		if (map.current) return;
		map.current = new mapboxgl.Map({
			container: mapContainer.current,
			style: "mapbox://styles/mapbox/streets-v12",
			center: [lng, lat],
			zoom: zoom,
		});
	});

	return (
		<div className={styles.map}>
			<div ref={mapContainer}></div>
		</div>
	);
};

export { Map };
