import React from "react";
import styles from "./styles.module.scss";
import { Heading, RotatingArrow } from "components";
import { Link } from "react-router-dom";

interface ImageGroupProps {
	ImageGroupArray: any[];
	MobileImageGroupArray: any[];
	overlay?: boolean;
}

const ImageGroup: React.FC<ImageGroupProps> = ({
	ImageGroupArray,
	MobileImageGroupArray,
	overlay,
}) => {
	return (
		<>
			<div className={styles.imageGroup}>
				{overlay ? (
					<div className={`${styles.imageGroup__overlay} row0`}>
						{ImageGroupArray.map((member, id) => (
							<div className={styles.imageGroup__overlay__div}>
								{" "}
								<Link to={member.linkTo}>
									<div
										className={`row ${styles.imageGroup__overlay__div__layer}`}
									>
										{" "}
										<Heading
											heading={member.layerTitle}
											fontSize="4.5vw"
											textAlign
										/>
									</div>
								</Link>
								<img
									className={styles.imageGroup__overlay__div__image}
									key={id}
									src={member.image}
									alt="not found"
								/>
							</div>
						))}
					</div>
				) : (
					<div className={`${styles.imageGroup__images} row0`}>
						{ImageGroupArray.map((image, id) => (
							<img loading="lazy" key={id} src={image} alt="not found" />
						))}
					</div>
				)}
				<div className={styles.imageGroup__rotatingArrow}>
					<RotatingArrow color={"white"} />
				</div>
			</div>
			<div className={styles.mobileImageGroup}>
				{overlay ? (
					<div className={`${styles.mobileImageGroup__overlay} column0`}>
						{MobileImageGroupArray.map((member, id) => (
							<div className={styles.mobileImageGroup__overlay__div}>
								{" "}
								<Link to={member.linkTo}>
									<div
										className={`row ${styles.mobileImageGroup__overlay__div__layer}`}
									>
										{" "}
										<Heading
											heading={member.layerTitle}
											fontSize="4.5vw"
											textAlign
										/>
									</div>
								</Link>
								<img
									className={styles.mobileImageGroup__overlay__div__image}
									key={id}
									src={member.image}
									alt="not found"
								/>
							</div>
						))}
					</div>
				) : (
					<div className={`${styles.mobileImageGroup__images} column0`}>
						{MobileImageGroupArray.map((image, id) => (
							<img loading="lazy" key={id} src={image} alt="not found" />
						))}
					</div>
				)}
				<div className={styles.mobileImageGroup__rotatingArrow}>
					<RotatingArrow
						color="white"
						width="75px"
						top="18%"
						left="40%"
						arrowHeight="22px"
						className={styles.mobileRotatingArrow}
					/>
				</div>
			</div>
		</>
	);
};

export { ImageGroup };
