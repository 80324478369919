import React from "react";
import styles from "./styles.module.scss";
import {
	TemplateDiv,
	RotatingArrow,
	Heading,
	Faqs,
	Statement,
} from "components";
import { FaqImage } from "assets";

const SectionSeven = () => {
	return (
		<>
			<TemplateDiv color="white" className={`${styles.template7} column0`}>
				<div className={`${styles.template7__top} row0`}>
					<Heading
						heading="FREQUENTLY ASKED QUESTIONS"
						className={styles.template7__top__heading}
					/>
					<Statement
						dotColor={"#833E21"}
						statement={"A CURATION OF SOME COMMONLY ASKED QUESTIONS"}
						alignment={false}
					/>
				</div>
				<div className={`${styles.template7__bottom} row0`}>
					<div className={styles.template7__bottom__image}>
						<div className={styles.template7__bottom__image__layer}></div>
						<img loading="lazy" src={FaqImage} alt="Not found" />
					</div>
					<div className={`${styles.template7__bottom__faq} column0`}>
						<Faqs />
						<RotatingArrow color="white" />
					</div>
				</div>
			</TemplateDiv>
			<TemplateDiv
				color="white"
				className={`${styles.template7__mobile} column0`}
			>
				<Heading
					heading="FREQUENTLY ASKED QUESTIONS"
					className={styles.template7__mobile__heading}
				/>
				<div className={`${styles.template7__mobile__top} row2`}>
					<RotatingArrow
						color="white"
						width="90px"
						arrowHeight="30%"
						top="21%"
						left="40%"
					/>
					<Statement
						dotColor={"#833E21"}
						statement={"A CURATION OF SOME COMMONLY ASKED QUESTIONS"}
						alignment={false}
						className={styles.statement}
					/>
				</div>
				<hr style={{ color: "white" }} />

				<div className={`${styles.template7__mobile__faq}`}>
					<Faqs />
				</div>

				<div className={styles.template7__mobile__image}>
					<div className={styles.template7__mobile__image__layer}></div>
					<img loading="lazy" src={FaqImage} alt="Not found" />
				</div>
			</TemplateDiv>
		</>
	);
};

export { SectionSeven };
