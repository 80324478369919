import React from 'react'
import styles from "./styles.module.scss"
import { HeroArrow } from "assets";

const DiscoverArrow = () => {
  return (
		<>
			<div className={`column ${styles.discover}`}>
				<p>DISCOVER</p>
				<HeroArrow className={styles.discover__arrow} />
			</div>
		</>
	);
}

export {DiscoverArrow}
