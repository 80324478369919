import React from 'react'
import {AboutUI} from "features"

const About = () => {
  
  return (
    <div>
      <AboutUI />
    </div>
  )
}

export {About}
