import { ContactUI } from 'features'
import React from 'react'

const Contact = () => {
  return (
    <div>
      <ContactUI />
    </div>
  )
}

export {Contact}
