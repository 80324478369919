import React from "react";
import styles from "./styles.module.scss";

interface ComingSoonProps {
	text: string;
}
const ComingSoon: React.FC<ComingSoonProps> = ({ text }) => {
	return (
		<>
			<div className={styles.comingSoon}>
				<p className={styles.comingSoon__paragraph}>{text}</p>
			</div>
		</>
	);
};

export { ComingSoon };
