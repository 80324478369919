import { TemplateDiv, RotatingArrow, Statement, Heading } from "components";
import React from "react";
import styles from "./styles.module.scss";
import { Porch } from "assets";

const SectionOne = () => {
	return (
		<>
			<TemplateDiv color="black" className={`${styles.template1}`}>
				<div className={`row0 ${styles.template1__parent}`}>
					<div className={`row2 ${styles.template1__parent__left}`}>
						<div className={`${styles.template1__parent__left__paragraph}`}>
							<p>ELEGANCE R</p>
							<p>LUXURIOUS</p>
						</div>
						<div className={`column1 ${styles.template1__parent__left__image}`}>
							<div className={`${styles.template1__parent__left__image__div}`}>
								<p>EDEFINED: OUR</p>
								<p>ACCOMODATIONS</p>
							</div>
							<img loading="lazy" src={Porch} alt="Not found" />
							<p>A view of one of our porches</p>
						</div>
					</div>
					<div className={`column0 ${styles.template1__parent__right}`}>
						<div
							className={`${styles.template1__parent__right__rotatingArrow}`}
						>
							<RotatingArrow color="black" />
						</div>
						<Statement
							dotColor={"#D79D5D"}
							blackColor={true}
							statement={"EVERY STAY, A GRAND EXPERIENCE"}
							alignment={true}
							className={styles.statement}
						/>
					</div>
				</div>
				<div className={`column0 ${styles.template1__mobile}`}>
					<Heading
						heading="ELEGANCE REDEFINED: OUR LUXURIOUS ACCOMODATIONS"
						className={styles.template1__mobile__heading}
					/>
					<div className={`column1 ${styles.template1__mobile__image}`}>
						<img loading="lazy" src={Porch} alt="Not found" />
						<p>A view of one of our porches</p>
					</div>
					<div className={`row2 ${styles.template1__mobile__bottom}`}>
						<Statement
							dotColor={"#D79D5D"}
							blackColor={true}
							statement={"EVERY STAY, A GRAND EXPERIENCE"}
							alignment={true}
							className={styles.statement}
						/>
						<RotatingArrow color={"black"} />
					</div>
				</div>
			</TemplateDiv>
		</>
	);
};

export { SectionOne };
