import React from "react";
import styles from "./styles.module.scss";
import { Dot } from "components";

interface StatementProps {
	dotColor: string;
	blackColor?: boolean;
	statement: string;
	alignment: boolean;
	className?: string;
}

const Statement: React.FC<StatementProps> = ({
	dotColor,
	statement,
	alignment,
	blackColor,
	className
}) => {
	return (
		<>
			<div
				className={`${styles.paragraph} ${className} column0`}
				style={
					alignment
						? { alignItems: "flex-start", textAlign: "start" }
						: { alignItems: "flex-end", textAlign: "end" }
				}
			>
				<p style={blackColor ? { color: "#565656" } : { color: "#fff8f8f7" }}>
					{statement}
				</p>
				<Dot color={dotColor} alone={true} className={styles.dot} />
			</div>
		</>
	);
};

export { Statement };
