import React from "react";
import { TemplateDiv, TwoRotatingArrows } from "components";
import styles from "./styles.module.scss";

const SectionOne = () => {
	return (
		<TemplateDiv color="black" className={`${styles.template1}`}>
			<TwoRotatingArrows
				paragraph="At Guided Hospitality, we stand on the pillars of ambition, integrity, and reliability. Founded with a vision to redefine luxury lodging, we cater exclusively to young professionals and entrepreneurs from around the world. We're here to offer more than just a place to stay; we provide a personalized, guided experience through the vibrant heart of Lagos.
We are not your typical micro-hotelier. We are your dependable ally, your strategic partner, and your trusted guide in Lagos. With a mission to become the largest distributed micro-hotelier in Nigeria, we are committed to helping you navigate Lagos' dynamic but often complex economic landscape.
Our commitment goes beyond the norm. We take the time to understand your unique needs and expectations, ensuring your journey in Lagos is as smooth and productive as possible. We're not just giving you a place to rest; we're giving you an experience, a community, and a way to explore Lagos like never before. Guided Hospitality is your gateway to a new kind of travel experience in Lagos."
                backgroundColor="#F0CCAA"
                headingFontSize="4vw"
				button={false}
				heading="ESTABLISHING LUXURIOUS COMFORT: OUR STORY"
			/>
		</TemplateDiv>
	);
};

export { SectionOne };
