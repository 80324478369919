import React from "react";
import styles from "./styles.module.scss";
import { Dot } from "components/whiteDot";

interface TemplateDivProps {
	color: string;
	children: any;
	className: string;
	backgroundColor?: string;
}

const TemplateDiv: React.FC<TemplateDivProps> = ({
	color,
	children,
	className,
	backgroundColor,
}) => {
	return (
		<>
			<div
				className={`${className} ${styles.template}`}
				style={{ backgroundColor: backgroundColor }}
			>
				<p className={`${styles.templateP} row2`} style={{ color: `${color}` }}>
					guided hospitality 2023 <Dot color={color} />
				</p>
				{children}
			</div>
		</>
	);
};

export { TemplateDiv };
