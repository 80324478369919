import React from "react";
import styles from "./styles.module.scss";
import { Button, CarouselComponent, Heading, TemplateDiv } from "components";
import {
	InteriorPrivate1,
	InteriorPrivate4,
	InteriorPrivate2,
	InteriorPrivate3,
	LagoonSide1,
	LagoonSide2,
	LagoonSide3,
	LagoonSide4,
} from "assets";

const SectionThree = () => {
	const suitesInfo = [
		{
			images: [
				{ image: LagoonSide1, desc: "" },
				{ image: LagoonSide2, desc: "" },
				{ image: LagoonSide3, desc: "" },
				{ image: LagoonSide4, desc: "" },
			],
			roomName: "LAGOON SIDE EXECUTIVE",
			roomDescription:
				"Experience unmatched luxury and comfort at our elite Ikoyi hotel, where contemporary elegance meets Lagos's vibrant spirit. Our Lagoon View Suite offers a serene retreat for international business travelers with a KING-size bed, high-speed internet, and a Jacuzzi tub. Enjoy captivating lagoon views amidst local art, plus 24-hour dining, a pristine pool, and secure car service.",
			perks: [
				"Free Continental Breakfast",
				"Transportation and Laundry Included",
			],
			location: "MARION APARTMENTS, Ikoyi, Lagos",
			price: "₦80,000.00/Night",
			popular: true,
		},
		{
			images: [
				{ image: InteriorPrivate1, desc: "" },
				{ image: InteriorPrivate2, desc: "" },
				{ image: InteriorPrivate3, desc: "" },
				{ image: InteriorPrivate4, desc: "" },
			],
			roomName: "INTERIOR PRIVATE EXECUTIVE",
			roomDescription:
				"Discover our private executive room, designed for savvy business travelers. Each room features a cozy king-size bed, high-speed internet, and captivating local art. Compact yet well-equipped with a smart TV, mini-fridge, and lounge chairs, it's a blend of efficiency and comfort. Access our standout amenities, including the pool, 24-hour dining, and Satin car service, all at our prestigious Banana Island Road address in Ikoyi.",
			perks: [
				"Free Continental Breakfast",
				"Transportation and Laundry Included",
			],
			location: "MARION APARTMENTS, Ikoyi, Lagos",
			price: "₦60,000.00/Night",
			popular: false,
		},
	];
	return (
		<TemplateDiv color="black" className={`column0 ${styles.template3}`}>
			<Heading heading="OUR SUITES" className={styles.template3__heading} />
			{suitesInfo.map((suite, idx) => (
				<>
					<div className={styles.template3__details} key={idx}>
						<hr className={styles.template3__details__hr} />
						<div className={`row2 ${styles.template3__details__specifics}`}>
							<div
								className={styles.template3__details__specifics__carouselDiv}
							>
								<CarouselComponent
									items={suite.images}
									className={
										styles.template3__details__specifics__carouselDiv__carousel
									}
									innerDirections
								/>
								{suite.popular === true && (
									<p
										className={
											styles.template3__details__specifics__carouselDiv__paragraph
										}
									>
										Most popular
									</p>
								)}
							</div>
							<div
								className={`column0 ${styles.template3__details__specifics__text}`}
							>
								<Heading
									heading={suite.roomName}
									className={`${styles.template3__details__specifics__text__heading}`}
								/>
								<p
									className={`${styles.template3__details__specifics__text__description}`}
								>
									{suite.roomDescription}
								</p>
								<div
									className={`${styles.template3__details__specifics__text__perks}`}
								>
									<p
										className={`${styles.template3__details__specifics__text__perks__intro}`}
									>
										The suite perks includes:
									</p>
									<ul
										className={`${styles.template3__details__specifics__text__perks__list}`}
									>
										{suite.perks.map((perk, idx) => (
											<li key={idx}>{perk}</li>
										))}
									</ul>
								</div>
								<p
									className={`${styles.template3__details__specifics__text__location}`}
								>
									Location: {suite.location}
								</p>
								{/* <p
									className={`${styles.template3__details__specifics__text__price}`}
								>
									Price: Starting from <span>{suite.price}</span>
								</p> */}
								<Button
									text="Book this suite"
									linkTo="/book-a-suite"
									black
									className={styles.button}
								/>
							</div>
						</div>
					</div>
				</>
			))}
		</TemplateDiv>
	);
};

export { SectionThree };
