import React from "react";
import styles from "./styles.module.scss";
import {
	TemplateDiv,
	RotatingArrow,
	CarouselComponent,
	Statement,
	MainCarousel,
} from "components";

import { Carousel1, Carousel2, Carousel3 } from "assets";

const SectionSix = () => {
	const carouselItems = [
		{
			image: Carousel1,
			desc: "The sitting area",
		},
		{
			image: Carousel2,
			desc: "The dining area",
		},
		{
			image: Carousel3,
			desc: "A view of the Lagoon side bedroom",
		},
	];
	return (
		<>
			<TemplateDiv color="white" className={`column0 ${styles.template6}`}>
				<RotatingArrow color="white" />
				<MainCarousel className={styles.carousel} />

				<Statement
					statement="LUXURY MEETS LOCAL EXPERIENCE"
					dotColor="white"
					alignment={true}
					className={styles.template6__statement}
				/>
			</TemplateDiv>
			<TemplateDiv
				color="white"
				className={`column0 ${styles.template6__mobile}`}
			>
				<div className={`row ${styles.carousel}`}>
					<CarouselComponent items={carouselItems} original className="row" />
				</div>

				<div className={`row2 ${styles.template6__mobile__bottom}`}>
					{" "}
					<Statement
						statement="LUXURY MEETS LOCAL EXPERIENCE"
						dotColor="white"
						alignment={true}
						className={styles.template6__mobile__bottom__statement}
						
					/>
					<RotatingArrow color="white" />
				</div>
			</TemplateDiv>
		</>
	);
};

export { SectionSix };
