import React from "react";
import { Heading, RotatingArrow, TemplateDiv } from "components";
import styles from "./styles.module.scss";
import { Wine1, Wine2, Wine3, Wine4, Wine5 } from "assets";

const SectionFive = () => {
	const wines = [Wine1, Wine2, Wine3, Wine4, Wine5];
	return (
		<>
			<TemplateDiv color="black" className={`column0 ${styles.template5}`}>
				<div className={`row0 ${styles.template5__top}`}>
					<div className={` ${styles.template5__top__left}`}>
						<Heading
							heading="WINE AND DINE WITH ELEGANCE"
							color="black"
							className={styles.template5__mobile__top__left__heading}
						/>
					</div>

					<div className={`column2 ${styles.template5__top__right}`}>
						<p>
							Our extensive wine list complements our culinary offerings. Allow
							our knowledgeable staff to guide you in choosing the perfect wine
							pairing to enhance your dining experience.
						</p>
						<RotatingArrow color="black" />
					</div>
				</div>
				<div className={`row2 ${styles.template5__bottom}`}>
					<div className={`row2 ${styles.template5__bottom__images}`}>
						{wines.map((wine, idx) => {
							return (
								<img loading="lazy" src={wine} alt="Not found" key={idx} />
							);
						})}
					</div>
				</div>
			</TemplateDiv>
			<TemplateDiv
				color="black"
				className={`column0 ${styles.template5__mobile}`}
			>
				<div className={`column0 ${styles.template5__mobile__top}`}>
					<div className={` ${styles.template5__mobile__top__left}`}>
						<Heading
							heading="WINE AND DINE WITH ELEGANCE"
							color="black"
							className={styles.template5__mobile__top__left__heading}
						/>
					</div>

					<div className={`column2 ${styles.template5__mobile__top__right}`}>
						<p>
							Our extensive wine list complements our culinary offerings. Allow
							our knowledgeable staff to guide you in choosing the perfect wine
							pairing to enhance your dining experience.
						</p>
						<RotatingArrow
							color="black"
							width="90px"
							top="25%"
							left="40%"
							arrowHeight="28px"
						/>
					</div>
				</div>
				<div className={`row2 ${styles.template5__mobile__bottom}`}>
					<div className={`row2 ${styles.template5__mobile__bottom__images}`}>
						{wines.map((wine, idx) => {
							return (
								<img loading="lazy" src={wine} alt="Not found" key={idx} />
							);
						})}
					</div>
				</div>
			</TemplateDiv>
		</>
	);
};

export { SectionFive };
