import React from "react";
import styles from "./styles.module.scss";
import { ScrollDown, ScrollDownArrowBlack, ScrollDownArrowWhite } from "assets";

interface RotatingArrowProps {
	color: string;
	width?: string;
	top?: string;
	left?: string;
	arrowHeight?: string;
	className?: string;
}

const RotatingArrow: React.FC<RotatingArrowProps> = ({
	color,
	width,
	top,
	left,
	arrowHeight,
	className,
}) => {
	return (
		<>
			<div className={`${className} ${styles.rotateDiv}`}>
				<ScrollDown
					className={styles.rotate}
					stroke={color === "black" ? "" : "#ffffff90"}
					style={
						width
							? { width: `${width}`, height: `${width}` }
							: { width: "123px", height: "123px" }
					}
				/>
				{color === "black" ? (
					<ScrollDownArrowBlack
						className={styles.arrow}
						style={
							width
								? { top: `${top}`, left: `${left}`, width: `${arrowHeight}` }
								: { top: "30%", left: "37%", width: `${arrowHeight}` }
						}
					/>
				) : (
					<ScrollDownArrowWhite
						className={styles.arrow}
						style={
							width
								? { top: `${top}`, left: `${left}`, width: `${arrowHeight}` }
								: { top: "30%", left: "37%", width: `${arrowHeight}` }
						}
					/>
				)}
			</div>
		</>
	);
};

export { RotatingArrow };
