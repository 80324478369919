import React from "react";
import { TemplateDiv, TestComponent } from "components";
import styles from "./styles.module.scss";
import { ExperienceLagos } from "assets";

const SectionThree = () => {
	return (
		<TemplateDiv color="white" className={`${styles.template3} row`}>
			<TestComponent
				image={ExperienceLagos}
				heading={"EXPERIENCE LAGOS, ONE BITE AT A TIME"}
				paragraph={
					"Our dining service is not just about food; it's about creating memories. Join us in savoring the very best of Lagos through our exceptional dining service. Whether you're here for business or leisure, our culinary offerings are sure to make your stay with Guided Hospitality an unforgettable one."
				}
			/>
		</TemplateDiv>
	);
};

export { SectionThree };
