import React from "react";
import styles from "./styles.module.scss";
import { Heading } from "components";
import { useLocation } from "react-router-dom";
const SectionOne = () => {
	const location = useLocation();
	const params = new URLSearchParams(location.search);
	const checkIn = params.get("checkin");
	const checkOut = params.get("checkout");
	return (
		<>
			<div className={`column0 ${styles.template1}`}>
				<Heading
					heading="book a suite"
					color="white"
					className={styles.template1__heading}
				/>
				<hr />
				<div className={`row ${styles.template1__frame}`}>
					<iframe
						src={`https://hotels.cloudbeds.com/reservation/URK3WM?widget=1#checkin=${checkIn}&checkout=${checkOut}`}
						title="CloudBeds Widget"
					></iframe>
				</div>
			</div>
		</>
	);
};

export { SectionOne };
