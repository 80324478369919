import React from "react";
import styles from "./styles.module.scss";
import { RedirectArrowWhite, RedirectArrowBlack } from "assets";
import { Link } from "react-router-dom";

interface ButtonProps {
	text: string;
	linkTo: any;
	className?: any;
	blank?: boolean;
	black?: boolean;
	onClick?: any;
}

const Button: React.FC<ButtonProps> = ({
	text,
	linkTo,
	blank = false,
	className,
	black,
	onClick,
}) => {
	const link = blank ? "_blank" : "_self";
	return (
		<>
			<Link
				to={linkTo}
				className={
					black
						? `row ${styles.buttonLink} ${className} ${styles.black}`
						: `row ${styles.buttonLink} ${className} ${styles.white}`
				}
				id="target"
				onClick={onClick}
				target={link}
			>
				<span className={styles.buttonLink__text}>{text}</span>{" "}
				<RedirectArrowWhite id="white" className={styles.redirectWhite} />
				<RedirectArrowBlack id="black" className={`${styles.redirectBlack}`} />
			</Link>
		</>
	);
};

export { Button };
