import React from "react";
import styles from "./styles.module.scss";
import { Navbar } from "./navbar";
import { Footer } from "./footer";

// import { BeforeFooterComponent } from "./BalconyChilling";

export interface BaseLayoutProps {
	children: any;
	// active: pageType;
}

const BaseLayout: React.FC<BaseLayoutProps> = ({ children }) => {
	return (
		<>
			<Navbar />
			<main className={styles.main}>{children}</main>
			<Footer />
		</>
	);
};

export { BaseLayout };
