import React from "react";
import styles from "./styles.module.scss";
import { DiscoverArrow } from "components/discoverArrow";
import { Statement } from "components/statements";
import { RotatingArrow } from "components/rotatingArrow";
import { Heading } from "components/heading";

interface HeroComponentProps {
	image: string;
	statement: string;
	header: string;
	backgroundColor: string;
	dotColor: string;
	top?: string;
}

const HeroComponent: React.FC<HeroComponentProps> = ({
	image,
	statement,
	header,
	backgroundColor,
	dotColor,
	top,
}) => {
	return (
		<>
			<div
				className={`${styles.heroComponent} `}
				style={{ backgroundColor: backgroundColor }}
			>
				<div className={`column2 ${styles.test}`}>
					<div className={`row ${styles.heroComponent__top}`}>
						<div className={styles.heroComponent__top__statement}>
							<Statement
								statement={statement}
								dotColor={"white"}
								alignment={true}
								className={styles.statement}
							/>
						</div>
						<div className={`row ${styles.heroComponent__top__headingImage}`}>
							<div
								className={`${styles.heroComponent__top__headingImage__heading}`}
								style={{ top: top }}
							>
								<Heading
									heading={header}
									textAlign={true}
									fontSize={"3.5vw"}
									className={`${styles.heroComponent__top__headingImage__heading__head}`}
								/>
							</div>
							<div
								className={`${styles.heroComponent__top__headingImage__image}`}
							>
								<img src={image} alt="Not found" />
							</div>
						</div>
						<div className={styles.heroComponent__top__rotatingArrow}>
							<RotatingArrow color={"white"} />
						</div>
					</div>
					<div className={styles.heroComponent__discoverArrow}>
						<DiscoverArrow />
					</div>
				</div>
				<div className={`column2 ${styles.mobile}`}>
					<Heading heading={header} className={styles.mobile__heading} />
					<div className={` ${styles.mobile__image}`}>
						<img loading="lazy" src={image} alt="Not found" />
					</div>
					<div className={`row2 ${styles.mobile__bottom}`}>
						<Statement
							dotColor={dotColor}
							statement={statement}
							alignment={true}
							className={styles.statement}
						/>
						<RotatingArrow
							color="white"
							width="90px"
							top="23%"
							left="40%"
							arrowHeight="25px"
						/>
					</div>
					<div className={styles.heroComponent__discoverArrow}>
						<DiscoverArrow />
					</div>
				</div>
			</div>
		</>
	);
};

export { HeroComponent };
