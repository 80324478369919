import React from "react";
import styles from "./styles.module.scss";
import { WaterFrontView } from "assets";
import { TemplateDiv, Heading, Button, RotatingArrow } from "components";

const SectionTwo = () => {
	return (
		<>
			{" "}
			<TemplateDiv color="white" className={`${styles.template2} row0`}>
				<div className={styles.template2__left}>
					<Heading heading="SERENITY AND WATERFRONT VIEWS: A PEACEFUL RETREAt" className={styles.template2__left__header} />
					<div className={styles.template2__left__paragraph}>
						<p>
							Welcome to Guided Hospitality, your premier choice of
							accommodation and personalized guidance in the bustling city of
							Lagos, Nigeria. Located in the elite neighborhood of Ikoyi, we
							offer suites specially designed with the modern business traveler
							in mind, offering serene waterfront views and a vibrant local art
							scene. We’re not just a micro-hotelier; we are your trusted ally
							in Lagos, ensuring your visit is productive, comfortable, and
							memorable.
						</p>
						<p>
							Experience a new standard of hospitality, where luxury meets local
							experience, and where you're not just a guest, you're a valued
							partner. At Guided Hospitality, we prioritize your needs above
							all. Start your Lagos journey with us, and let us guide you to the
							best the city has to offer.
						</p>
					</div>
					<Button text="about us" linkTo="./about" className={styles.template2__left__button}/>
				</div>
				<div className={styles.template2__right}>
					<div className={`${styles.template2__right__layer} row1`}>
						<div className={styles.template2__right__layer__div}>
							<RotatingArrow color="black" />
						</div>
					</div>
					<img
						src={WaterFrontView}
						alt=""
						className={styles.template2__right__img}
					/>
				</div>
			</TemplateDiv>
		</>
	);
};

export { SectionTwo };
