import React from "react";
import styles from "./styles.module.scss";
import { RotatingArrow } from "components/rotatingArrow";
import { Heading } from "components/heading";

interface SecondHeroComponentProps {
	image: string;
	mobileImage: string;
	header: string;
	backgroundColor: string;
}

const SecondHeroComponent: React.FC<SecondHeroComponentProps> = ({
	image,
	header,
	backgroundColor,
	mobileImage,
}) => {
	return (
		<>
			<div
				className={`column0 ${styles.secondHeroComponent} `}
				style={{ backgroundColor: backgroundColor }}
			>
				<div className={`row2 ${styles.secondHeroComponent__top}`}>
					<Heading
						heading={header}
						fontSize={"3.8vw"}
						className={styles.secondHeroComponent__top__heading}
					/>

					<div className={styles.secondHeroComponent__top__rotatingArrow}>
						<RotatingArrow color={"white"} />
					</div>
				</div>
				<div className={`${styles.secondHeroComponent__bottom} `}>
					{window.innerWidth >= 768 ? (
						<img loading="lazy" src={image} alt="Not found" />
					) : (
						<img loading="lazy" src={mobileImage} alt="Not found" />
					)}
				</div>
				<RotatingArrow
					color="white"
					width="90px"
					top="23%"
					left="39%"
					arrowHeight="28px"
					className={styles.mobileRotatingArrow}
				/>
			</div>
		</>
	);
};

export { SecondHeroComponent };
