import { TransportUI } from 'features'
import React from 'react'

const Transport = () => {
  return (
    <div>
      <TransportUI />
    </div>
  )
}

export {Transport}
