import React from "react";
import {
	SecondHeroComponent,
	BrandSpectrum,
	BeforeFooterComponent,
} from "components";
import { AboutHero, AboutHeroMobile } from "assets";
import { SectionOne } from "./sectionOne";
import { SectionTwo } from "./sectionTwo";

const AboutUI = () => {
	return (
		<div>
			<SecondHeroComponent
				image={AboutHero}
				mobileImage={AboutHeroMobile}
				header={"EMPOWERING YOUR LAGOS STAY: OUR AIM AT GUIDED HOSPITALITY"}
				backgroundColor={"#833E21"}
				
			/>
			<BrandSpectrum />
			<SectionOne />
			<BrandSpectrum />
			<SectionTwo />
			<BeforeFooterComponent />
		</div>
	);
};

export { AboutUI };
