/**
 * ROUTES
 *
 * ===============================================
 *
 * This object depicts the component url structure.
 * It contains a key-value pair of components and their respective URLs
 *
 */

export const Routes = {
	// General
	home: "/",
	about: "/about",
	contact: "/contact-us",
	dining: "/dining",
	transport: "/transport",
	suites: "/suites",
	booking: "/book-a-suite",
};
