import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { Logo } from "assets";
import { Link } from "react-router-dom";
import ReactGA from "react-ga";

const Navbar = () => {
	const [scrolling, setScrolling] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY >= 700) {
				setScrolling(true);
			} else {
				setScrolling(false);
			}
		};

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	const navbarClass = scrolling
		? `${styles.navbar} ${styles.brown}`
		: `${styles.navbar} ${styles.transparent}`;
	const navbarClassMobile = `${styles.mobile} ${styles.brown}`;

	const [openMenu, setOpenMenu] = useState(false);
	const [toggle, setToggle] = useState(false);

	const handleToggle = () => {
		setToggle(!toggle);
		setOpenMenu(!openMenu);
	};
	const mobileHandleToggleTransport = () => {
		setToggle(!toggle);
		setOpenMenu(!openMenu);
		setActive({
			tranportActive: true,
			diningActive: false,
			suitesActive: false,
			aboutUsActive: false,
			contactUsActive: false,
		});
	};
	const mobileHandleToggleDining = () => {
		setToggle(!toggle);
		setOpenMenu(!openMenu);
		setActive({
			tranportActive: false,
			diningActive: true,
			suitesActive: false,
			aboutUsActive: false,
			contactUsActive: false,
		});
	};
	const mobileHandleToggleAbout = () => {
		setToggle(!toggle);
		setOpenMenu(!openMenu);
		setActive({
			tranportActive: false,
			diningActive: false,
			suitesActive: false,
			aboutUsActive: true,
			contactUsActive: false,
		});
	};
	const mobileHandleToggleContact = () => {
		setToggle(!toggle);
		setOpenMenu(!openMenu);
		setActive({
			tranportActive: false,
			diningActive: false,
			suitesActive: false,
			aboutUsActive: false,
			contactUsActive: true,
		});
	};
	const mobileHandleToggleSuites = () => {
		setToggle(!toggle);
		setOpenMenu(!openMenu);
		setActive({
			tranportActive: false,
			diningActive: false,
			suitesActive: true,
			aboutUsActive: false,
			contactUsActive: false,
		});
	};
	const [active, setActive] = useState({
		tranportActive: false,
		diningActive: false,
		suitesActive: false,
		aboutUsActive: false,
		contactUsActive: false,
	});
	const transportActiveTab = active.tranportActive ? `${styles.active}` : "";
	const diningActiveTab = active.diningActive ? `${styles.active}` : "";
	const suitesActiveTab = active.suitesActive ? `${styles.active}` : "";
	const aboutUsActiveTab = active.aboutUsActive ? `${styles.active}` : "";
	const contactUsActiveTab = active.contactUsActive ? `${styles.active}` : "";
	const bookSuiteClicked = () => {
		ReactGA.event({
			category: "Button Click",
			action: "Book a Suite Button Clicked",
			label: "Book a Suite",
		});
	};

	return (
		<>
			<nav className={`${navbarClass} row2`}>
				<div className={transportActiveTab}>
					<Link
						to="/transport"
						onClick={() =>
							setActive({
								tranportActive: true,
								diningActive: false,
								suitesActive: false,
								aboutUsActive: false,
								contactUsActive: false,
							})
						}
					>
						satin rides
					</Link>
				</div>
				<div className={diningActiveTab}>
					<Link
						to="/dining"
						onClick={() =>
							setActive({
								tranportActive: false,
								diningActive: true,
								suitesActive: false,
								aboutUsActive: false,
								contactUsActive: false,
							})
						}
					>
						dining
					</Link>
				</div>
				<div className={suitesActiveTab}>
					<Link
						to="/suites"
						onClick={() =>
							setActive({
								tranportActive: false,
								diningActive: false,
								suitesActive: true,
								aboutUsActive: false,
								contactUsActive: false,
							})
						}
					>
						suites
					</Link>
				</div>

				<Link
					to="/"
					className={styles.navbar__image}
					onClick={() =>
						setActive({
							tranportActive: false,
							diningActive: false,
							suitesActive: false,
							aboutUsActive: false,
							contactUsActive: false,
						})
					}
				>
					{" "}
					<div>
						<img loading="lazy" src={Logo} alt="Not found" />
					</div>
				</Link>
				<div className={aboutUsActiveTab}>
					<Link
						to="/about"
						onClick={() =>
							setActive({
								tranportActive: false,
								diningActive: false,
								suitesActive: false,
								aboutUsActive: true,
								contactUsActive: false,
							})
						}
					>
						about us
					</Link>
				</div>
				<div className={contactUsActiveTab}>
					<Link
						to="/contact-us"
						onClick={() =>
							setActive({
								tranportActive: false,
								diningActive: false,
								suitesActive: false,
								aboutUsActive: false,
								contactUsActive: true,
							})
						}
					>
						Contact us
					</Link>
				</div>
				<Link
					to="/book-a-suite"
					className={styles.book}
					onClick={bookSuiteClicked}
				>
					<div>Book a suite</div>
				</Link>
			</nav>
			<div className={`${navbarClassMobile} column0`}>
				<div className={`${styles.mobile__top} row2`}>
					<div onClick={handleToggle} className={`row ${styles.button}`}>
						<div
							className={
								toggle
									? `${styles.container} ${styles.change}`
									: `${styles.container}`
							}
							onClick={handleToggle}
						>
							<div className={styles.bar1}></div>
							<div className={styles.bar2}></div>
							<div className={styles.bar3}></div>
						</div>
					</div>
					<Link to="/" className={`row ${styles.mobile__top__logo}`}>
						<div>
							<img loading="lazy" src={Logo} alt="Not found" />
						</div>
					</Link>
					<Link
						to="/book-a-suite"
						className={styles.book}
						onClick={bookSuiteClicked}
					>
						<div>Book a suite</div>
					</Link>
				</div>
				{openMenu ? (
					<nav className={`${styles.navbarMobile} column0`}>
						<Link
							to="/transport"
							onClick={mobileHandleToggleTransport}
							className={transportActiveTab}
						>
							Transport
						</Link>
						<Link
							to="/dining"
							onClick={mobileHandleToggleDining}
							className={diningActiveTab}
						>
							dining
						</Link>
						<Link
							to="/suites"
							onClick={mobileHandleToggleSuites}
							className={suitesActiveTab}
						>
							suites
						</Link>

						<Link
							to="/about"
							onClick={mobileHandleToggleAbout}
							className={aboutUsActiveTab}
						>
							about us
						</Link>
						<Link
							to="/contact-us"
							onClick={mobileHandleToggleContact}
							className={contactUsActiveTab}
						>
							Contact us
						</Link>
					</nav>
				) : (
					""
				)}
			</div>
		</>
	);
};

export { Navbar };
