import React from "react";
import styles from "./styles.module.scss";

interface HeadingProps {
	heading: string;
	sub?: string;
	color?: string;
	fontSize?: string;
	textAlign?: boolean;
	className?: string;
}

const Heading: React.FC<HeadingProps> = ({
	heading,
	sub,
	color,
	fontSize,
	textAlign,
	className
}) => {
	return (
		<>
			<p
				className={`${className} ${styles.heading}`}
				style={{
					color: color,
					fontSize: fontSize,
					textAlign: textAlign ? "center" : "left",
					whiteSpace: 'pre-line'
				}}
			>
				{heading}
			</p>
			{sub && (
				<h4
					className={`${styles.sub}`}
					style={{
						color: "#D69D5D",
						fontSize: "60px",
						textAlign: textAlign ? "center" : "left",
					}}
				>
					{sub}
				</h4>
			)}
		</>
	);
};

export { Heading };
