import { CulinaryBliss1, CulinaryBliss2 } from "assets";
import { TemplateDiv, RotatingArrow, Heading, Button } from "components";
import styles from "./styles.module.scss";
import React from "react";

const SectionFive: React.FC = () => {
	return (
		<>
			<TemplateDiv color="black" className={`${styles.template5} row2`}>
				<div className={styles.template5__imageLeft}>
					<div className={`${styles.template5__imageLeft__layer} row1`}>
						<div className={styles.template5__imageLeft__layer__div}>
							<RotatingArrow
								color="white"
								width="90px"
								top="21%"
								left="40%"
								arrowHeight="30%"
							/>
						</div>
					</div>
					<img loading="lazy" src={CulinaryBliss1} alt="Not found" />
				</div>

				<div className={`column2 ${styles.template5__text}`}>
					<Heading
						heading="CULINARY BLISS AT YOUR FINGERTIPS"
						color="black"
						textAlign
					/>
					<div className={`column2 ${styles.template5__text__paragraph}`}>
						<p>
							At Guided Hospitality, dining is an art form. Our skilled chefs
							craft exquisite dishes, blending creativity and tradition to
							delight your palate. Whether you prefer international cuisine or
							local flavors, our culinary offerings cater to all tastes.
						</p>
						<p>
							Indulge in private, intimate dinners, dine al fresco with a view,
							or savor a meal in our elegant dining spaces. Our attentive staff
							ensures every meal is a memorable experience, offering wine
							pairings and accommodating dietary needs.
						</p>
						<p>
							Join us in savoring the very best of Lagos through our exceptional
							dining service. Delight in gourmet meals, anytime, anywhere.
						</p>
					</div>
					<Button text="explore the menu" linkTo="./dining" black />
				</div>
				<div className={styles.template5__imageRight}>
					{" "}
					<img loading="lazy" src={CulinaryBliss2} alt="Not found" />
				</div>
			</TemplateDiv>
			<TemplateDiv color="black" className={`${styles.template5__mobile} row2`}>
				<div className={styles.template5__mobile__imageLeft}>
					<div className={`${styles.template5__mobile__imageLeft__layer} row1`}>
						<div className={styles.template5__mobile__imageLeft__layer__div}>
							<RotatingArrow
								color="white"
								width="90px"
								top="21%"
								left="40%"
								arrowHeight="30%"
							/>
						</div>
					</div>
					<img loading="lazy" src={CulinaryBliss1} alt="Not found" />
				</div>

				<div className={`column2 ${styles.template5__mobile__text}`}>
					<Heading
						heading="CULINARY BLISS AT YOUR FINGERTIPS"
						color="black"
						textAlign
						className={styles.template5__mobile__text__heading}
					/>
					<div
						className={`column2 ${styles.template5__mobile__text__paragraph}`}
					>
						<p>
							At Guided Hospitality, dining is an art form. Our skilled chefs
							craft exquisite dishes, blending creativity and tradition to
							delight your palate. Whether you prefer international cuisine or
							local flavors, our culinary offerings cater to all tastes.
						</p>
						<p>
							Indulge in private, intimate dinners, dine al fresco with a view,
							or savor a meal in our elegant dining spaces. Our attentive staff
							ensures every meal is a memorable experience, offering wine
							pairings and accommodating dietary needs.
						</p>
						<p>
							Join us in savoring the very best of Lagos through our exceptional
							dining service. Delight in gourmet meals, anytime, anywhere.
						</p>
					</div>
					<Button text="explore the menu" linkTo="./dining" black />
				</div>
				<div className={styles.template5__mobile__imageRight}>
					{" "}
					<img loading="lazy" src={CulinaryBliss2} alt="Not found" />
				</div>
			</TemplateDiv>
		</>
	);
};

export { SectionFive };
