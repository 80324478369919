import React from "react";
import { ImageGroup, TemplateDiv } from "components";
import styles from "./styles.module.scss";
import {
	Car1,
	Car1Mobile,
	SingaporeNoodles,
	SingaporeNoodlesMobile,
	SittingRoom,
	SittingRoomMobile,
} from "assets";

const SectionTwo = () => {
	const servicesGroup = [
		{ image: SittingRoom, layerTitle: "OUR SUITES", linkTo: "/suites" },
		{ image: SingaporeNoodles, layerTitle: "OUR DINING", linkTo: "/dining" },
		{ image: Car1, layerTitle: "OUR RIDES", linkTo: "/transport" },
	];
	const mobileServicesGroup = [
		{ image: SittingRoomMobile, layerTitle: "OUR SUITES", linkTo: "/suites" },
		{
			image: SingaporeNoodlesMobile,
			layerTitle: "OUR DINING",
			linkTo: "/dining",
		},
		{ image: Car1Mobile, layerTitle: "OUR RIDES", linkTo: "/transport" },
	];
	return (
		<TemplateDiv color="white" className={`${styles.template2}`}>
			<ImageGroup
				ImageGroupArray={servicesGroup}
				overlay
				MobileImageGroupArray={mobileServicesGroup}
			/>
		</TemplateDiv>
	);
};

export { SectionTwo };
