import React, { useState } from "react";
import styles from "./styles.module.scss";
import { Button, DiscoverArrow, Heading } from "components";

const Hero = () => {
	const today = new Date();
	const tomorrow = new Date(today);
	tomorrow.setDate(tomorrow.getDate() + 1);

	const formatDate = (date) => {
		const d = new Date(date);
		let month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();

		if (month.length < 2)
			month = '0' + month;
		if (day.length < 2)
			day = '0' + day;

		return [year, month, day].join('-');
	}
	const [checkDetails, setCheckDetails] = useState({
		checkIn: formatDate(today), // set default checkIn to today's date
		checkOut: formatDate(tomorrow), // set default checkOut to tomorrow's date
	});
	return (
		<div className={`${styles.heroDiv} column`}>
			<Heading
				className={styles.heroDiv__header}
				// heading="
				// 		THE BEST ROOMS IN LAGOS
				// 		PEACFUL, CLEAN, COMFORTABLE AND AFFORDABLE
				// 	" 
				heading={`The Best Stay in Lagos \n Water Views and More`}
				sub="Book A Room Today!"
				textAlign
			/>

			<form className={`row1 ${styles.heroDiv__input}`}>
				<div className={`${styles.heroDiv__input__checkIn} column0`}>
					<label htmlFor="checkIn">CHECK-IN</label>
					<input
						name="checkIn"
						id="checkIn"
						type="date"
						placeholder="SELECT DATE"
						value={checkDetails.checkIn}
						onChange={(e) =>
							setCheckDetails((prev) => {
								return { ...prev, checkIn: e.target.value };
							})
						}
						required
					/>
				</div>
				<div className={`${styles.heroDiv__input__checkOut} column0`}>
					<label htmlFor="CheckOut">CHECK-OUT</label>
					<input
						name="checkOut"
						id="checkOut"
						type="date"
						placeholder="SELECT DATE"
						value={checkDetails.checkOut}
						onChange={(e) =>
							setCheckDetails((prev) => {
								return { ...prev, checkOut: e.target.value };
							})
						}
						required
					/>
				</div>

				<Button
					text="book a suite"
					linkTo={`/book-a-suite?checkin=${checkDetails.checkIn}&checkout=${checkDetails.checkOut}`}
					className={styles.heroButton}
				/>
			</form>
			<div className={styles.discoverArrow}>
				<DiscoverArrow />
			</div>
		</div>
	);
};

export { Hero };
