import React from "react";
import styles from "./styles.module.scss";

interface ClosingTextProps {
	width?: string;
}
const ClosingText: React.FC<ClosingTextProps> = ({ width }) => {
	return (
		<>
			<div
				className={`${styles.closingText}`}
				style={{ width: width ? width : "45%" }}
			>
				<p className={styles.closingText__about}>
					<span>We</span> are eager to help you experience Lagos in a unique
					way. Whether you have questions about our services or need assistance
					with your booking, our dedicated team is ready to help.
				</p>
				<p className={styles.closingText__support}>
					Our support team is available 24/7 to assist you with any inquiries or
					requests. We look forward to hosting you at Guided Hospitality.
				</p>
				<p className={styles.closingText__email}>
					Email Address:{" "}
					<a href="mailto:https://info@guidedlabs.io">info@guidedlabs.io</a>
				</p>
				<p className={styles.closingText__whatsapp}>
					WhatsApp Number: +13143100377
				</p>
			</div>
		</>
	);
};

export { ClosingText };
