import {FaqDetails } from "assets";
import React from "react";
import styles from "./styles.module.scss";

interface FAQITemProps {
	active: number;
	state: number;
	changeActive: (state: number) => void;
	question: string;
	answer: string;
}

interface FAQListProp {
	question: string;
	answer: string;
}

const FAQItem: React.FC<FAQITemProps> = ({
	active,
	state,
	changeActive,
	question,
	answer,
}) => {
	const contentEl = React.useRef<HTMLDivElement>(null);
	return (
		<div
			className={`${styles.faqItem} ${
				active === state ? styles.activeItem : styles.inactiveItem
			}`}
		>
			<button
				onClick={() => changeActive(active === state ? -1 : state)}
				className={styles.faqBtn}
			>
				<span>{question}</span>{" "}
				{state === active ? (
					<FaqDetails style={{ transform: "rotate(45deg)" }} />
				) : (
					<FaqDetails />
				)}
			</button>
			<div
				ref={contentEl}
				className={styles.faqBody}
				style={
					active === state && contentEl.current
						? { height: contentEl.current.scrollHeight }
						: { height: "0px" }
				}
			>
				<div>{answer}</div>
			</div>
		</div>
	);
};

const Faqs = () => {
	const [active, setActive] = React.useState(-1);

	const faqs: FAQListProp[] = [
		{
			question: "Is Guided Hospitality a hotel?",
			answer: `Yes, Guided Hospitality is a full-service Micro Hotel. We offer a compact footprint combined with expansive services, ensuring our guests receive unparalleled support and value during their stay.`,
		},
		{
			question: "What types of accommodations do you offer? ",
			answer: `We offer a range of luxurious suites specially designed to cater to the needs of discerning travelers. Our suites provide spacious comfort and upscale amenities, creating an environment that combines the convenience of home with the luxury of a high-end retreat.`,
		},
		{
			question: "Can you accommodate special dietary requests?",
			answer: `Absolutely! Our skilled culinary team is prepared to accommodate a variety of dietary preferences and restrictions. Simply inform us of your requirements, and we will ensure your dining experience is tailored to your needs.`,
		},
		{
			question:
				"What sets Guided Hospitality apart from other accommodations in Lagos? ",
			answer: `Guided Hospitality is your trusted ally in Lagos, offering not just accommodation but a curated experience. Our personalized service, attention to detail, and commitment to your comfort and satisfaction set us apart. We blend luxury with local culture to create an unforgettable stay.`,
		},
		{
			question: "How can I book my stay with Guided Hospitality?",
			answer: `Booking your stay with us is easy. You can make a reservation directly through our website, or you can contact our reservations team for assistance. We're here to ensure your booking process is smooth and your stay is exceptional.`,
		},
	];

	return (
		<section className={`appContainer ${styles.faqContainer}`}>
			<section className={styles.faqList}>
				{faqs.map((item, index) => (
					<FAQItem
						{...item}
						key={index}
						state={index}
						active={active}
						changeActive={(x) => setActive(x)}
					/>
				))}
			</section>
		</section>
	);
};

export { Faqs };
