import React, { useState } from "react";
import styles from "./styles.module.scss";
import { Carousel1, Carousel2, Carousel3, NextArrow, PrevArrow } from "assets";

interface MainCarouselProps {
	className?: string;
}
const MainCarousel: React.FC<MainCarouselProps> = ({ className }) => {
	const [activeSlide, setActiveSlide] = useState(1);
	const carouselItems = [
		{
			image: Carousel1,
			desc: "The sitting area",
		},
		{
			image: Carousel2,
			desc: "The dining area",
		},
		{
			image: Carousel3,
			desc: "A view of the Lagoon side bedroom",
		},
	];
	const previousImage = () => {
		if (activeSlide === 0) {
			goToSlide(2);
		} else {
			goToSlide(activeSlide - 1);
		}
	};

	const nextImage = () => {
		if (activeSlide === carouselItems.length - 1) {
			goToSlide(0);
		} else {
			goToSlide(activeSlide + 1);
		}
	};

	const goToSlide = (slideNumber) => {
		if (slideNumber >= 0 && slideNumber < carouselItems.length) {
			setActiveSlide(slideNumber);
		}
	};

	return (
		<>
			<div className={styles.carouselContainer}>
				<PrevArrow
					onClick={previousImage}
					className={`${styles.arrow} ${styles.prev}`}
				/>
				{carouselItems.map(({ image, desc }, idx) => (
					<div
						key={idx}
						className={`${styles.carouselSlide} ${
							idx === activeSlide ? styles.active : ""
						}`}
					>
						<div></div>
						<img loading="lazy" src={image} alt="Not Found" />
						<p>{desc}</p>
					</div>
				))}
				<NextArrow
					onClick={nextImage}
					className={`${styles.arrow} ${styles.next}`}
				/>
			</div>
		</>
	);
};

export { MainCarousel };
