import React, { useState } from "react";
import styles from "./styles.module.scss";
import { NextArrow, PrevArrow } from "assets";

interface CarouselProps {
	items: any[];
	className?: string;
	innerDirections?: boolean;
	original?: boolean;
}

const CarouselComponent: React.FC<CarouselProps> = ({
	items,
	className,
	innerDirections,
	original,
}) => {
	const [currentImageIndex, setCurrentImageIndex] = useState(0);

	const nextImage = () => {
		setCurrentImageIndex((prevIndex) =>
			prevIndex === items.length - 1 ? 0 : prevIndex + 1
		);
	};

	const previousImage = () => {
		setCurrentImageIndex((prevIndex) =>
			prevIndex === 0 ? items.length - 1 : prevIndex - 1
		);
	};

	return (
		<div className={` ${styles.carousel} ${className}`}>
			{innerDirections ? (
				<>
					<div className={`column0 ${styles.content}`}>
						<PrevArrow
							onClick={previousImage}
							className={`${styles.content__arrow} ${styles.content__leftArrow}`}
						/>
						<img
							loading="lazy"
							src={items[currentImageIndex].image}
							alt="Carousel item"
						/>
						<p>{items[currentImageIndex].desc}</p>
						<NextArrow
							onClick={nextImage}
							className={`${styles.content__arrow} ${styles.content__rightArrow}`}
						/>
					</div>
				</>
			) : (
				<>
					<PrevArrow onClick={previousImage} />
					<div className={`column0 ${styles.originalContent}`}>
						<img
							loading="lazy"
							src={items[currentImageIndex].image}
							alt="Carousel item"
						/>
						<p>{items[currentImageIndex].desc}</p>
					</div>
					<NextArrow onClick={nextImage} />
				</>
			)}
		</div>
	);
};

export { CarouselComponent };
