import React from "react";
import { SectionFive } from "./sectionFive";
import { SectionTwo } from "./sectionTwo";
import { SectionOne } from "./sectionOne";
import { SectionThree } from "./sectionThree";
import { SectionFour } from "./sectionFour";
import { SectionSix } from "./sectionSix";
import { SectionSeven } from "./sectionSeven";
import { Hero } from "./hero";
import { BeforeFooterComponent } from "components/beforeFooter";
import { BalconyChilling } from "assets";

const HomeUI = () => {
	return (
		<div>
			<Hero />
			<SectionOne />
			<SectionTwo />
			<SectionThree />
			<SectionFour />
			<SectionFive />
			<SectionSix />
			<SectionSeven />
			<BeforeFooterComponent image={BalconyChilling} />
		</div>
	);
};

export { HomeUI };
