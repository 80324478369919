import { DiningUI } from "features";
import React from "react";

const Dining = () => {
	return (
		<div>
			<DiningUI />
		</div>
	);
};

export { Dining };
