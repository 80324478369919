import React from "react";
import { SuitesUI } from "features";

const Suites = () => {
	return (
		<div>
			<SuitesUI />
		</div>
	);
};

export { Suites };
