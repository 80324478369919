import React from "react";
import { SectionOne } from "./sectionOne";
import { SectionTwo } from "./sectionTwo";
import { SectionThree } from "./sectionThree";
import { SectionFour } from "./sectionFour";
import { SectionFive } from "./sectionFive";
import { SectionSix } from "./sectionSix";
import { BeforeFooterComponent, HeroComponent } from "components";
import { DiningHero, BalconyChilling } from "assets";

const DiningUI = () => {
	return (
		<div>
			<HeroComponent
				image={DiningHero}
				statement={"SAVOR LUXURY, ONE BITE AT A TIME"}
				header={"EXQUISITE DINING: ELEVATE YOUR PALATE WITH GUIDED HOSPITALITY"}
				backgroundColor={"#B52200"}
				dotColor="black"
				top="-12%"
			/>
			<SectionOne />
			<SectionTwo />
			<SectionThree />
			<SectionFour />
			<SectionFive />
			<SectionSix />
			<BeforeFooterComponent image={BalconyChilling} />
		</div>
	);
};

export { DiningUI };
