import React from "react";
import styles from "./styles.module.scss";
import { SecondHeroComponent, BeforeFooterComponent } from "components";
import { ContactHero, ContactHeroMobile } from "assets";
import { SectionOne } from "./sectionOne";

const ContactUI = () => {
	return (
		<div className={styles.contact}>
			<SecondHeroComponent
				image={ContactHero}
				mobileImage={ContactHeroMobile}
				header={"CONTACT US AT GUIDED HOSPITALITY"}
				backgroundColor={"#21221A"}
			/>
			
			<SectionOne />
			{/* <SectionOne /> */}
			<BeforeFooterComponent />
		</div>
	);
};

export { ContactUI };
