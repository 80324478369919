import { RedirectArrowBlack, RedirectArrowWhite } from "assets";
import styles from "./styles.module.scss";
import React from "react";

interface RedirectProps {
	text: string;
	link?: string;
	mail?: boolean;
	black?: boolean;
}

const Redirect: React.FC<RedirectProps> = ({ text, link, mail, black }) => {
	return (
		<>
			<a
				href={mail ? `mailto:${link}` : `${link}`}
				className={styles.redirectLink}
			>
				{text} {black ? <RedirectArrowBlack /> : <RedirectArrowWhite />}
			</a>
		</>
	);
};

export { Redirect };
