import React from "react";
import styles from "./styles.module.scss";
import { Button, Heading, RotatingArrow } from "components";

interface TestComponentProps {
	metricNumber?: string;
	reversed?: boolean;
	image: string;
	heading: string;
	buttonText?: string;
	paragraph: string;
	linkTo?: string;
	transportPerks?: any[];
}

const TestComponent: React.FC<TestComponentProps> = ({
	image,
	metricNumber,
	reversed,
	paragraph,
	heading,
	linkTo,
	buttonText,
	transportPerks,
}) => {
	return (
		<>
			<div
				className={
					reversed
						? `row2 ${styles.reversed} ${styles.testComponent}`
						: `${styles.testComponent} row2`
				}
			>
				<div className={`${styles.testComponent__text}`}>
					<Heading
						heading={heading}
						className={styles.testComponent__text__heading}
					/>

					<p className={`${styles.testComponent__text__paragraph}`}>
						{paragraph}
					</p>
					{metricNumber && (
						<div className={`row0 ${styles.testComponent__text__metric}`}>
							<p className={`${styles.testComponent__text__metric__paragraph}`}>
								<span>{metricNumber}</span>local delicacies{" "}
							</p>
							<p className={`${styles.testComponent__text__metric__paragraph}`}>
								<span>{metricNumber}</span>foreign delicacies
							</p>
						</div>
					)}
					{transportPerks && (
						<div
							className={`row0 ${styles.testComponent__text__transportPerks}`}
						>
							{transportPerks.map((perk, idx) => (
								<div
									key={idx}
									className={`column2 ${styles.testComponent__text__transportPerks__perk}`}
								>
									{perk.vector}
									<p>{perk.text}</p>
								</div>
							))}
						</div>
					)}
					{buttonText ? (
						<Button
							linkTo={`${linkTo}`}
							text={buttonText}
							className={styles.button}
							blank
						/>
					) : (
						<Button
							linkTo="https://guided-hospitality-24-7.ps.me/"
							text={"Explore the menu"}
							blank={true}
							className={styles.button}
						/>
					)}
				</div>
				<div className={`${styles.testComponent__imageDiv}`}>
					<img loading="lazy" src={image} alt="Not found" />
					<div className={`${styles.testComponent__imageDiv__rotatingArrow}`}>
						<RotatingArrow
							color="white"
							width="100px"
							top="25%"
							left="42%"
							arrowHeight="26px"
							className={styles.mobileRotatingArrow}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export { TestComponent };
