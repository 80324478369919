import React from "react";
import styles from "./styles.module.scss";
import { TickCircle } from "assets";

export interface AmenityCardProps {
	text: string;
}
const AmenityCard: React.FC<AmenityCardProps> = ({ text }) => {
	return (
		<>
			<div className={`row2 ${styles.amenity}`}>
				<TickCircle className={styles.tickCircle} />
				<p>{text}</p>
			</div>
		</>
	);
};

export { AmenityCard };
