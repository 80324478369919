import React from "react";
import {
	TemplateDiv,
	TwoRotatingArrows,
} from "components";
import styles from "./styles.module.scss";

const SectionFour = () => {
	return (
		<TemplateDiv color="black" className={`${styles.template4}`}>
			<TwoRotatingArrows
				paragraph="Food is more than sustenance; it's an experience that engages all our
					senses. At Guided Hospitality, we believe in the transformative power
					of a beautifully prepared meal. It has the ability to transport you to
					a different world, to evoke cherished memories, and to create new
					ones. We invite you to join us in savoring the essence of food. It's
					not just a meal; it's an experience that connects people, places, and
					memories. From the first bite to the last, we aim to make every dining
					experience unforgettable."
				backgroundColor="#f6f5e1"
				button={true}
				heading="CAPTURING THE ESSENCE OF FOOD"
			/>
		</TemplateDiv>
	);
};

export { SectionFour };
