import React from "react";
import { TemplateDiv, TestComponent } from "components";
import styles from "./styles.module.scss";
import { CulinaryExcellence } from "assets";

const SectionOne = () => {
	return (
		<TemplateDiv color="white" className={`${styles.template1} row`}>
			<TestComponent
				reversed={true}
				image={CulinaryExcellence}
				heading="INDULGE YOUR TASTE BUDS IN CULINARY EXCELLENCE"
				paragraph="At Guided Hospitality, dining is a cherished part of the guest experience. Our culinary team is dedicated to crafting exquisite dishes that elevate your stay to new heights. We invite you to explore our culinary world, where every meal is an event, and every bite is a celebration of flavor."
				metricNumber="20+"
			/>
		</TemplateDiv>
	);
};

export { SectionOne };
