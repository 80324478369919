import React from "react";
import styles from "./styles.module.scss";
import { ImageGroup, TemplateDiv } from "components";
import {
	SecondBedRoom,
	CabinetShelf,
	ArtRoom,
	SecondBedRoomMobile,
	CabinetShelfMobile,
	ArtRoomMobile,
} from "assets";

const SectionFour = () => {
	const sectionFourAreas = [SecondBedRoom, CabinetShelf, ArtRoom];
	const sectionFourAreasMobile = [
		SecondBedRoomMobile,
		CabinetShelfMobile,
		ArtRoomMobile,
	];
	return (
		<TemplateDiv color="white" className={`${styles.template4} row`}>
			<ImageGroup
				ImageGroupArray={sectionFourAreas}
				MobileImageGroupArray={sectionFourAreasMobile}
			/>
		</TemplateDiv>
	);
};

export { SectionFour };
