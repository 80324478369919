import {
	Home,
	About,
	Contact,
	Dining,
	Transport,
	Suites,
	Booking,
} from "pages";
import { PathRouteProps } from "react-router-dom";
import { Routes } from "./routes";
import { BaseLayout } from "layouts";
// import { Modals } from "components";

// Route Builder Item Props
export interface RouteBuilderItem extends PathRouteProps {
	Layout?: React.FC<any>; // If you wish to add a layout to the page
	Element: React.FC;
	props?: any;
}

/**
 * ROUTE BUILDER
 *
 * ===============================================
 *
 * This is a list of all our application routes.
 *
 * A single item on this list contains the necessary Route props needed by React Router to do it's job.
 *
 * If you wish to add a new route to the application,
 * just fulfill all the necessary props needed by the RouteBuilder item. Ciao!
 *
 */
export const RouteBuilder: RouteBuilderItem[] = [
	{
		path: Routes.home,
		Element: Home,
		Layout: BaseLayout,
	},
	{
		path: Routes.about,
		Element: About,
		Layout: BaseLayout,
	},
	{
		path: Routes.contact,
		Element: Contact,
		Layout: BaseLayout,
	},
	{
		path: Routes.dining,
		Element: Dining,
		Layout: BaseLayout,
	},
	{
		path: Routes.transport,
		Element: Transport,
		Layout: BaseLayout,
	},
	{
		path: Routes.suites,
		Element: Suites,
		Layout: BaseLayout,
	},
	{
		path: Routes.booking,
		Element: Booking,
		Layout: BaseLayout,
	},
];
