import React from "react";
import { Button, Heading, RotatingArrow } from "components";
import styles from "./styles.module.scss";

interface TwoRotatingArrowsProps {
	backgroundColor: string;
	paragraph: string;
	button?: boolean;
	heading: string;
	headingFontSize?: string;
}

const TwoRotatingArrows: React.FC<TwoRotatingArrowsProps> = ({
	backgroundColor,
	paragraph,
	button,
	headingFontSize,
	heading,
}) => {
	return (
		<>
			<div
				style={{
					backgroundColor: backgroundColor,
					justifyContent: "space-between",
				}}
				className={`${styles.twoArrows} row2`}
			>
				<RotatingArrow
					color="black"
					width="160px"
					top="38%"
					left="42%"
					className={styles.arrow}
				/>
				<div className={`column2 ${styles.twoArrows__middleText}`}>
					<Heading
						heading={heading}
						color="black"
						textAlign={true}
						fontSize={headingFontSize}
						className={styles.heading}
					/>
					<p className={`${styles.twoArrows__middleText__paragraph}`}>
						{paragraph}
					</p>
					{button ? (
						<Button
							text="Explore the menu"
							blank={true}
							linkTo="https://guided-hospitality-24-7.ps.me/"
							black={true}
							className={styles.button}
						/>
					) : (
						""
					)}
					<RotatingArrow
						color="black"
						width="125px"
						top="32%"
						left="39%"
						arrowHeight="32px"
						className={styles.mobileRotatingArrow}
					/>
				</div>
				<RotatingArrow
					color="black"
					width="160px"
					top="38%"
					left="42%"
					className={styles.arrow}
				/>
			</div>
		</>
	);
};

export { TwoRotatingArrows };
