import {
	AmenityCard,
	AmenityCardProps,
	ClosingText,
	Heading,
	Locations,
	TemplateDiv,
} from "components";
import React from "react";
import styles from "./styles.module.scss";

const SectionTwo = () => {
	const amenities: AmenityCardProps[] = [
		{ text: "room service" },
		{ text: "Swimming Pool" },
		{ text: "24-hour Front desk" },
		{ text: "airport shuttle" },
		{ text: "all-inclusive meals" },
		{ text: "laundry service" },
		{ text: "internet" },
		{ text: "car for hire" },
		{ text: "bar" },
		{ text: "tour assistance" },
		{ text: "spa service" },
		{ text: "Concierge" },
	];
	return (
		<TemplateDiv className={styles.template2} color={"black"}>
			<div className={styles.template2__top}>
				<Heading
					heading="OUR AMENITIES"
					color="#0A0A0A"
					className={styles.template2__top__heading}
				/>
				<hr />
				<div className={`${styles.template2__top__details} row2`}>
					<div className={`${styles.template2__top__details__left}`}>
						{amenities.map((amenity) => {
							return <AmenityCard {...amenity} />;
						})}
					</div>
					<ClosingText width="42%" />
				</div>
				<hr />
			</div>
			<Locations />
		</TemplateDiv>
	);
};

export { SectionTwo };
