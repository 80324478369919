import React from "react";
import styles from "./styles.module.scss";

interface WhiteDotProps {
	className?: string;
	color?: string;
	alone?: boolean;
}

const Dot: React.FC<WhiteDotProps> = ({ className, color, alone }) => {
	return (
		<>
			<div
				className={`${styles.circle} ${className} ${
					alone ? styles.noMargin : styles.margin
				}`}
				style={{ backgroundColor: `${color}` }}
			>
				{" "}
				<span>.</span>
			</div>
		</>
	);
};

export { Dot };
