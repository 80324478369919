import React from "react";
import { SectionOne } from "./sectionOne";
import { SectionTwo } from "./sectionTwo";
import { SectionThree } from "./sectionThree";
import { SectionFour } from "./sectionFour";

import { BeforeFooterComponent, HeroComponent } from "components";
import { SuitesBeforeFooter, SuitesHero } from "assets";

const SuitesUI = () => {
	return (
		<div>
			<HeroComponent
				image={SuitesHero}
				statement={"SAVOR LUXURY, ONE BITE AT A TIME"}
				header={"WHERE COMFORT MEETS LUXURY: DISCOVER OUR SUITES"}
				backgroundColor={"#022626"}
				dotColor={"white"}
				top="-18%"
			/>
			<SectionOne />
			<SectionTwo />
			<SectionThree />

			<SectionFour />
			<BeforeFooterComponent
				image={SuitesBeforeFooter}
				templateTextColor="black"
				backgroundColor="#F6F5E1"
				paragraph="We provide seamless journeys, ensuring you arrive in style and depart with ease. Your transportation, your convenience – experience first-class travel with us."
				heading="TRAVEL WITH OUR EXCLUSIVE TRANSPORT"
				headingColor="black"
				paragraphColor="black"
				rotatingArrowColor="black"
			/>
		</div>
	);

};

export { SuitesUI };
