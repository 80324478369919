import React, { useState } from "react";
import styles from "./styles.module.scss";
import {
	Button,
	Heading,
	TemplateDiv,
	Locations,
	ClosingText,
} from "components";
import emailjs from "emailjs-com";

const SectionOne: React.FC = () => {
	const [userDetails, setUserDetails] = useState({
		name: "",
		email: "",
		message: "",
		phone: "",
	});
	const [sent, setSent] = useState(false);

	const handleSubmit = (e) => {
		e.preventDefault();
		if (userDetails.message !== "") {
			emailjs
				.send(
					"service_b38gjj7",
					"template_k3jqe9e",
					userDetails,
					"YTfHxYGS_xh65G62e"
				)
				.then((res) => {
					console.log(res);
				})
				.catch((err) => {
					console.log(err);
				});
			setUserDetails({ name: "", email: "", message: "", phone: "" });
			setSent(true);
			setTimeout(() => {
				setSent(false);
			}, 3000);
		} else {
			alert("Please leave a message");
		}
	};
	return (
		<TemplateDiv className={styles.template1} color={"black"}>
			<div className={styles.template1__top}>
				<Heading
					heading="CONTACT US"
					color="#0A0A0A"
					className={styles.template1__top__heading}
				/>
				<hr />
				<div className={`${styles.template1__top__details} row2`}>
					<ClosingText />
					<div className={`${styles.template1__top__details__right}`}>
						<p className={styles.template1__top__details__right__header}>
							Send us a message
						</p>
						<form
							onSubmit={handleSubmit}
							className={`column0 ${styles.template1__top__details__right__form}`}
							id="contact-mail-form"
						>
							<input
								type="text"
								placeholder="Enter your name"
								name="name"
								value={userDetails.name}
								onChange={(e) =>
									setUserDetails((prev) => ({
										...prev,
										name: e.target.value,
									}))
								}
								required
							/>
							<input
								type="email"
								placeholder="Enter your email address"
								name="email"
								value={userDetails.email}
								onChange={(e) =>
									setUserDetails((prev) => ({
										...prev,
										email: e.target.value,
									}))
								}
								required
							/>
							<input
								type="number"
								placeholder="Enter your phone number"
								value={userDetails.phone}
								onChange={(e) =>
									setUserDetails((prev) => ({ ...prev, phone: e.target.value }))
								}
								required
							/>
							<textarea
								name=""
								placeholder="Enter your message"
								value={userDetails.message}
								onChange={(e) =>
									setUserDetails((prev) => ({
										...prev,
										message: e.target.value,
									}))
								}
								required
							></textarea>
						</form>
						<Button
							text={"Submit"}
							black
							linkTo={""}
							onClick={handleSubmit}
							className={styles.button}
						/>

						{sent && (
							<div className={`row ${styles.toast}`} role="alert">
								<svg
									aria-hidden="true"
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 18 20"
								>
									<path
										stroke="#8eb28e"
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="2"
										d="m9 17 8 2L9 1 1 19l8-2Zm0 0V9"
									/>
								</svg>
								<div>Message sent successfully.</div>
							</div>
						)}
					</div>
				</div>
				<hr />
			</div>
			<Locations />
		</TemplateDiv>
	);
};

export { SectionOne };
