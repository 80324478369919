import { BookingUI } from 'features'
import React from 'react'

const Booking = () => {
  return (
    <>
      <BookingUI />
    </>
  )
}

export {Booking}
