import React, { useState } from "react";
import styles from "./styles.module.scss";
import { TemplateDiv, RotatingArrow, Heading } from "components";
import { InteriorExecutive, LagoonExecutive } from "assets";
// import { getPrices, getToken } from "api";

const SectionThree = () => {
	// const client_id = process.env.CLIENT_ID;
	// const client_secret = process.env.CLIENT_SECRET;
	// const [roomRates, setRoomRates] = useState([]);
	// const getRoomRates = async () => {
	// 	try {
	// 		const response = await getToken({
	// 			grant_type: "code",
	// 			client_id: client_id,
	// 			client_secret: client_secret,
	// 			redirect_uri: "/",
	// 			code: "",
	// 		});
	// 		if (response) {
	// 			console.log(response);
	// 		}
	// 		if (!response) {
	// 			throw new Error(`Error fetching movie list: ${response}`);
	// 		}
	// 	} catch (error) {
	// 		console.error(error);
	// 		return [];
	// 	}
	// };
	// useEffect(() => {
	// 	getRoomRates();
	// }, []);

	const suitesArray = [
		{
			// startingPrice: roomRates[0],
			startingPrice: "20,000",
			roomName: "LAGOON SIDE EXECUTIVE",
			popular: true,
		},
		{
			// startingPrice: roomRates[1],
			startingPrice: "30,000",
			roomName: "INTERIOR PRIVATE EXECUTIVE",
			popular: false,
		},
	];
	const [imageDisplay, setImageDisplay] = useState(0);
	const images = [LagoonExecutive, InteriorExecutive];
	return (
		<>
			<TemplateDiv color="black" className={`row0 ${styles.template3}`}>
				<div className={`column0 ${styles.template3__left}`}>
					<p className={styles.template3__left__paragraph}>OUR SUITES</p>
					<Heading
						heading="LAVISH COMFORT, IMPECCABLE SERVICE"
						className={styles.template3__left__heading}
						color="black"
					/>
					<div className={`column0 ${styles.template3__left__suites}`}>
						{suitesArray.map((suite, idx) => (
							<div
								key={idx}
								className={`row2 ${styles.template3__left__suites__suite}`}
								onMouseOver={() => setImageDisplay(idx)}
							>
								<div className={styles.template3__left__suites__suite__image}>
									<img loading="lazy" src={images[idx]} alt="Not found" />
									{suite.popular && <p>Most popular</p>}
								</div>
								<div
									className={`column0 ${styles.template3__left__suites__suite__info}`}
								>
									<Heading
										heading={suite.roomName}
										color="black"
										className={
											styles.template3__left__suites__suite__info__header
										}
									/>
									{/* <p>
										Starting from <span>₦{suite.startingPrice}/Night</span>
									</p> */}
								</div>
							</div>
						))}
					</div>
				</div>
				<div className={styles.template3__right}>
					<div className={styles.template3__right__rotatingArrow}>
						<RotatingArrow color="white" />
					</div>
					<img loading="lazy" src={images[imageDisplay]} alt="Not found" />
				</div>
			</TemplateDiv>
		</>
	);
};

export { SectionThree };
