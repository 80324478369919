import React from "react";
import styles from "./styles.module.scss";
import { Logo } from "assets";
import { Dot, Redirect } from "components";

const Footer = () => {
	return (
		<>
			<div className={styles.footer}>
				<div className={`${styles.footer__top} row2`}>
					<div className={styles.footer__top__text}>
						<div>
							<h5>location</h5>
							<p>
								MARION APARTMENTS, FF62+C9G, Ajegunle Odo Rd (BANANA ISLAND
								Rd), Apartment A3, Ikoyi, Lagos,106104, Nigeria
							</p>
							<Redirect
								text="Open Google Maps"
								link="https://maps.google.com/?q=6.46042251586914,3.45170307159424"
							/>
						</div>
						<div>
							<h5>contact us</h5>
							<p>Phone: +2347080637513 </p>
							<p>WhatsApp: +2347080637513</p>

							<div className={`row2`}>
								<p>Email: </p>
								<Redirect
									text="info@guidedlabs.io"
									link="https://info@guidedlabs.io"
									mail
								/>
							</div>
						</div>
						<div>
							<h5>Open hours</h5>
							<p>Monday to Friday 8:00AM vs 5:00PM</p>
							<p>Saturday 8:00AM vs 5:00PM</p>
							<p>Sunday 8:00AM vs 5:00PM</p>
						</div>
					</div>
					<div className={styles.footer__top__footerLogo}>
						<img loading="lazy" src={Logo} alt="Not found" />
					</div>
				</div>
				<div className={`${styles.footer__privacyAndCookies} row`}>
					<p>© 2023 Guided Hospitality - Powered by Guided Labs</p>
					<Dot className={styles.footerDot} color="white" />
					<Redirect text="Privacy & Cookies" link="" />
				</div>
			</div>
		</>
	);
};

export { Footer };
