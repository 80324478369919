import React from "react";
import { TemplateDiv, TestComponent } from "components";
import styles from "./styles.module.scss";
import {
	SeamlessTransport,
	Luxury,
	SmoothRides,
	Comfort,
	CustomerService,
} from "assets";

const SectionTwo = () => {
	const perks = [
		{
			vector: <Luxury />,
			text: "luxury",
		},
		{
			vector: <Comfort />,
			text: "comfort",
		},
		{
			vector: <SmoothRides />,
			text: "Smooth rides",
		},
		{
			vector: <CustomerService />,
			text: "Seamless service",
		},
	];
	return (
		<TemplateDiv color="white" className={`${styles.template2} row`}>
			<TestComponent
				reversed={true}
				image={SeamlessTransport}
				heading="SEAMLESS TRANSPORT WITH SATIN RIDES"
				paragraph="Experience the epitome of luxury travel with Satin Rides, our exclusive transportation partner. Enjoy daily rides for up to 6 km per day per room in ruggedly luxurious Land Rover vehicles equipped with 5G internet. Our professional drivers and stringent safety measures ensure your journey across Lagos is not only comfortable but safe and timely. Travel in style with Satin Rides, where luxury is always in motion."
				buttonText="Learn more"
				linkTo="https://satinrides.com/"
				transportPerks={perks}
			/>
		</TemplateDiv>
	);
};

export { SectionTwo };
