import React from "react";
import styles from "./styles.module.scss";

const BrandSpectrum = () => {
	const brandSpectrumColors = [
		"#FFFFFF",
		"#E8E8E8",
		"#f6f5e1",
		"#f0ccaa",
		"#D79D5D",
		"#9B7850",
		"#833E21",
		"#3B0E0B",
		"#B52200",
		"#00111B",
		"#022626",
		"#21221A",
		"#BCC2B6",
	];
	return (
		<div className={`row0 ${styles.brandSpectrum}`}>
			{brandSpectrumColors.map((color, idx) => (
				<div
					key={idx}
					className={styles.brandSpectrum__colour}
					style={{ backgroundColor: color }}
				></div>
			))}
		</div>
	);
};

export { BrandSpectrum };
