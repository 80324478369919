import React from "react";
import { BeforeFooterComponent, HeroComponent } from "components";
import { TransportHero } from "assets";
// import { SectionOne } from "./sectionOne";
import { SectionTwo } from "./sectionTwo";
// import { SectionThree } from "./sectionThree";

const TransportUI = () => {
	return (
		<div>
			<HeroComponent
				image={TransportHero}
				statement={"ELEVATE YOUR TRAVEL EXPERIENCE"}
				header={"LUXURY IN MOTION: OUR TRANSPORT SERVICES"}
				backgroundColor={"#00111B"}
				dotColor="white"
				top="-16%"
			/>
			{/* <SectionOne /> */}
			<SectionTwo />
			{/* <SectionThree /> */}
			<BeforeFooterComponent />
		</div>
	);
};

export { TransportUI };
