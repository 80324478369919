import React from "react";
import styles from "./styles.module.scss";
import { ImageGroup, TemplateDiv } from "components";
import {
	BedRoom,
	BedRoomMobile,
	DiningRoom,
	DiningRoomMobile,
	SittingRoom,
	SittingRoomMobile,
} from "assets";

const SectionOne = () => {
	const sectionTwoAreas = [SittingRoom, BedRoom, DiningRoom];
	const sectionTwoAreasMobile = [
		SittingRoomMobile,
		BedRoomMobile,
		DiningRoomMobile,
	];
	return (
		<TemplateDiv color="white" className={`${styles.template1} row`}>
			<ImageGroup
				ImageGroupArray={sectionTwoAreas}
				MobileImageGroupArray={sectionTwoAreasMobile}
			/>
		</TemplateDiv>
	);
};

export { SectionOne };
