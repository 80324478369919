import React from "react";
import { ImageGroup, TemplateDiv } from "components";
import styles from "./styles.module.scss";
import {
	Tagliatelle,
	GrilledSalmon,
	SingaporeNoodles,
	SingaporeNoodlesMobile,
	GrilledSalmonMobile,
	TagliatelleMobile
} from "assets";

const SectionSix = () => {
	const sectionSixDelicacies = [Tagliatelle, GrilledSalmon, SingaporeNoodles];
	const sectionSixDelicaciesMobile = [
		TagliatelleMobile,
		GrilledSalmonMobile,
		SingaporeNoodlesMobile,
	];
	return (
		<TemplateDiv color="white" className={`${styles.template6}`}>
			<ImageGroup
				ImageGroupArray={sectionSixDelicacies}
				MobileImageGroupArray={sectionSixDelicaciesMobile}
			/>
		</TemplateDiv>
	);
};

export { SectionSix };
