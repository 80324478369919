import React from "react";
import { ImageGroup, TemplateDiv } from "components";
import styles from "./styles.module.scss";
import { FriedRice, BeefRamen, FruitSalad, FruitSaladMobile, BeefRamenMobile, FriedRiceMobile } from "assets";

const SectionTwo = () => {
	const sectionTwoDelicacies = [FriedRice, BeefRamen, FruitSalad];
	const sectionTwoDelicaciesMobile = [FriedRiceMobile, BeefRamenMobile, FruitSaladMobile];
	return (
		<TemplateDiv color="white" className={`${styles.template2}`}>
			<ImageGroup ImageGroupArray={sectionTwoDelicacies} MobileImageGroupArray={sectionTwoDelicaciesMobile}/>
		</TemplateDiv>
	);
};

export { SectionTwo };
