import React from "react";
import { TemplateDiv, TwoRotatingArrows } from "components";
import styles from "./styles.module.scss";

const SectionTwo = () => {
	return (
		<TemplateDiv color="black" className={`${styles.template2}`}>
			<TwoRotatingArrows
				paragraph="At Guided Hospitality, our suite service is the epitome of luxury. We provide spacious, well-appointed suites designed for both efficiency and relaxation. From plush king-size beds to high-speed internet and captivating views, our suites have it all. You'll find thoughtful amenities like smart TVs, mini-fridges, and cozy lounge chairs, creating a perfect blend of functionality and comfort.
But it's not just the room; it's the dedicated team, the 24-hour dining options, and the secure environment that make your stay truly exceptional. Experience luxury redefined with Guided Hospitality's suite service."
				backgroundColor="#BCC2B6"
				button={false}
				heading="UNPARALLEL SUITE SERVICE: YOUR LUXURY RETREAT"
			/>
		</TemplateDiv>
	);
};

export { SectionTwo };
