import React from "react";
import styles from "./styles.module.scss";
import { TemplateDiv, Heading, Button, RotatingArrow } from "components";
import { BalconyChilling } from "assets";

interface BeforeFooterComponentProps {
	image?: string;
	paragraph?: string;
	templateTextColor?: string;
	backgroundColor?: string;
	heading?: string;
	paragraphColor?: string;
	headingColor?: string;
	rotatingArrowColor?: string;
}

const BeforeFooterComponent: React.FC<BeforeFooterComponentProps> = ({
	image,
	paragraph,
	templateTextColor,
	headingColor,
	paragraphColor,
	backgroundColor,
	heading,
	rotatingArrowColor,
}) => {
	return (
		<>
			<TemplateDiv
				color={templateTextColor ? templateTextColor : "white"}
				className={`${styles.beforeFooter} column0`}
				backgroundColor={backgroundColor ? backgroundColor : "#833E21"}
			>
				<div className={`${styles.beforeFooter__top} row0`}>
					<Heading
						heading={
							heading ? heading : "GUIDED HOSPITALITY IS YOUR SECOND HOME"
						}
						color={headingColor}
						fontSize="4.5vw"
						className={styles.beforeFooter__top__heading}
					/>
					<div className={styles.beforeFooter__top__paragraph}>
						{paragraph ? (
							<p style={{ color: paragraphColor }}>{paragraph}</p>
						) : (
							<p>
								Your experience is our priority and your satisfaction is our
								joy. With this in mind, we have carefully laid in place,
								elements that’ll make you feel at home. We await your patronage.
							</p>
						)}

						<Button
							linkTo="/book-a-suite"
							text="book a suite"
							black={headingColor === "black" ? true : false}
							className={styles.button}
						/>
					</div>
				</div>
				<div className={`${styles.beforeFooter__bottom} row0`}>
					<div className={`${styles.beforeFooter__bottom__layer1}`}>
						{rotatingArrowColor ? (
							<RotatingArrow color={rotatingArrowColor} />
						) : (
							<RotatingArrow color="white" />
						)}
					</div>
					<div className={`${styles.beforeFooter__bottom__layer2} row1`}></div>
					<div className={`${styles.beforeFooter__bottom__image}`}>
						{image ? (
							<img loading="lazy" src={image} alt="Not found" />
						) : (
							<img loading="lazy" src={BalconyChilling} alt="Not found" />
						)}
					</div>
				</div>
			</TemplateDiv>
		</>
	);
};

export { BeforeFooterComponent };
