import { TemplateDiv, RotatingArrow, Statement, Heading } from "components";
import React from "react";
import styles from "./styles.module.scss";
import { EpicureanElegance } from "assets";

const SectionFour = () => {
	return (
		<>
			<TemplateDiv color="white" className={styles.template4}>
				<div className={`column2 ${styles.template4__parent}`}>
					<Heading
						heading="EPICUREAN ELEGANCE: A FEAST FOR THE SENSES AT GUIDED HOSPITALITY"
						className={styles.template4__parent__heading}
						textAlign
					/>

					<div className={styles.template4__parent__div}>
						<Statement
							dotColor={"black"}
							statement={"EXQUISITE DINING, A FLAVOURFUL JOURNEY"}
							alignment={true}
							className={styles.template4__parent__div__statement}
						/>
					</div>
					<div className={` ${styles.template4__parent__image}`}>
						<div className={styles.template4__parent__image__rotatingArrow}>
							<RotatingArrow color="white" width="140px" top="33%" left="37%" />
						</div>
						<img loading="lazy" src={EpicureanElegance} alt="Not found" />
						<p>One of the people’s favourite</p>
					</div>
				</div>
				<div className={`column2 ${styles.template4__mobile}`}>
					<Heading
						heading="EPICUREAN ELEGANCE: A FEAST FOR THE SENSES AT GUIDED HOSPITALITY"
						className={styles.template4__mobile__heading}
					/>
					<div className={`column1 ${styles.template4__mobile__image}`}>
						<img loading="lazy" src={EpicureanElegance} alt="Not found" />
						<p>One of the people’s favourite</p>
					</div>
					<div className={`row2 ${styles.template4__mobile__bottom}`}>
						<Statement
							dotColor={"black"}
							statement={"EXQUISITE DINING, A FLAVOURFUL JOURNEY"}
							alignment={true}
							className={styles.statement}
						/>
						<RotatingArrow
							color="white"
							width="100px"
							top="25%"
							left="43%"
							arrowHeight="25px"
						/>
					</div>
				</div>
			</TemplateDiv>
		</>
	);
};

export { SectionFour };
