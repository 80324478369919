import React from "react";
import styles from "./styles.module.scss";
import { SectionFour } from "./sectionFour";
import { BeforeFooterComponent } from "components";
import { SuitesBeforeFooter } from "assets";
import {SectionTwo} from "./sectionTwo";
import { SectionOne } from "./sectionOne";
const BookingUI = () => {
	return (
		<>
			<SectionOne />
			<SectionTwo />
			<SectionFour />
			<BeforeFooterComponent
				image={SuitesBeforeFooter}
				templateTextColor="black"
				backgroundColor="#F6F5E1"
				paragraph="We provide seamless journeys, ensuring you arrive in style and depart with ease. Your transportation, your convenience – experience first-class travel with us."
				heading="TRAVEL WITH OUR EXCLUSIVE TRANSPORT"
				headingColor="black"
				paragraphColor="black"
				rotatingArrowColor="black"
			/>
		</>
	);
};

export { BookingUI };
