import React, { useState } from "react";
import styles from "./styles.module.scss";
import { ComingSoon, Heading, Redirect, Map } from "components";
import { BuildingPool, PinpointLocatior, Sofa } from "assets";

const Locations = () => {
	const [active, setActive] = useState({
		marionApartments: true,
		orientalPenthouse: false,
		olMansCove: false,
	});
	const marionActive = () => {
		setActive({
			marionApartments: true,
			orientalPenthouse: false,
			olMansCove: false,
		});
	};
	const orientalActive = () => {
		setActive({
			marionApartments: false,
			orientalPenthouse: true,
			olMansCove: false,
		});
	};
	const olMansCoveActive = () => {
		setActive({
			marionApartments: false,
			orientalPenthouse: false,
			olMansCove: true,
		});
	};
	return (
		<>
			<section className={styles.locations__bottom}>
				<Heading
					heading="OUR LOCATIONS"
					color="#0A0A0A"
					className={styles.locations__bottom__heading}
				/>
				<div className={styles.locations__bottom__location}>
					<ul className={`row0 ${styles.locations__bottom__location__list}`}>
						<li
							className={styles.locations__bottom__location__list__item}
							onClick={() => marionActive()}
							style={
								active.marionApartments
									? { backgroundColor: "#d79d5d" }
									: { backgroundColor: "transparent" }
							}
						>
							<span>marion apartments</span>
						</li>
						<li
							onClick={() => orientalActive()}
							className={`row ${styles.locations__bottom__location__list__item}`}
							style={
								active.orientalPenthouse
									? { backgroundColor: "#d79d5d" }
									: { backgroundColor: "transparent" }
							}
						>
							<span>LAGOS ORIENTAL PENTHOUSE</span>
							<ComingSoon text={"Coming soon"} />
						</li>
						<li
							className={`row ${styles.locations__bottom__location__list__item}`}
							onClick={() => olMansCoveActive()}
							style={
								active.olMansCove
									? { backgroundColor: "#d79d5d" }
									: { backgroundColor: "transparent" }
							}
						>
							<span>OL MANS COVE</span>
							<ComingSoon text={"Coming soon"} />
						</li>
					</ul>
				</div>
				{active.marionApartments && (
					<>
						<div className={`row0 ${styles.marionApartments}`}>
							<div className={`column0 ${styles.marionApartments__left}`}>
								<img loading="lazy" src={BuildingPool} alt="Not found" />
								<Heading
									heading="MARION APARTMENTS"
									className={styles.marionApartments__left__heading}
								/>
								<div
									className={`row0 ${styles.marionApartments__left__address}`}
								>
									<PinpointLocatior
										className={styles.marionApartments__left__address__locator}
									/>
									<p
										className={
											styles.marionApartments__left__address__paragraph
										}
									>
										FF62+C9G, Ajegunle Odo Rd (BANANA ISLAND Road), Apartment
										A3, Ikoyi, Lagos,106104, Nigeria
									</p>
								</div>
								<div
									className={`row2 ${styles.marionApartments__left__availableRooms}`}
								>
									<Sofa />
									<p
										className={
											styles.marionApartments__left__availableRooms__paragraph
										}
									>
										2 rooms
									</p>
									<ComingSoon text="Available" />
								</div>
								<a
									href=" "
									className={`row2 ${styles.marionApartments__left__a}`}
								>
									<Redirect
										text="Get directions"
										link="https://maps.google.com/?q=6.46042251586914,3.45170307159424"
										black
									/>
								</a>
							</div>
							<div className={styles.marionApartments__right}>
								<Map />
							</div>
						</div>
					</>
				)}
				{active.orientalPenthouse && (
					<>
						<div className={`row ${styles.unavailable}`}>
							<Heading heading="Coming soon" />
						</div>
					</>
				)}
				{active.olMansCove && (
					<>
						<div className={`row ${styles.unavailable}`}>
							<Heading heading="Coming soon" />
						</div>
					</>
				)}
			</section>
		</>
	);
};

export { Locations };
